<template>
	<ObiRow class="w-100">
		<ObiCol col="12" class="obi-steps">
			<ObiText
				@click="$emit('change', index)"
				v-for="index in size"
				:key="index"
				class="obi-steps-item"
				:class="{ 'obi-steps-item--active': current >= index }"
			>
			</ObiText>
		</ObiCol>
	</ObiRow>
</template>

<script>
export default {
	name: "ObiInput",
	props: {
		value: {},
		size: {
			type: Number,
		},
		current: {
			type: Number,
			default: 1,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.obi-steps {
	display: flex;
	padding: 0;
	counter-reset: step;
	cursor: pointer;

	&-item {
		display: flex;
		flex-direction: column;
		flex: 1;
		text-align: center;
		position: relative;

		&:before {
			width: 3em;
			height: 3em;
			content: counter(step);
			counter-increment: step;
			align-self: center;
			background: #0b3d9f;
			color: #fff;
			border-radius: 100%;
			line-height: 3em;
			margin-bottom: 0.5em;
		}

		&:after {
			height: 2px;
			width: calc(100% - 4em);
			content: "";
			background: #ffffff;
			position: absolute;
			top: 1.5em;
			left: calc(50% + 2em);
		}

		&:last-child {
			&:after {
				content: none;
			}
		}

		&--active {
			&:before {
				background: #ffffff;
				color: #0842b5;
			}
		}

		&--complete {
			&:before {
				content: "✔";
				color: #fff;
				background: #000;
			}
			&:after {
				background: #0842b5;
			}
		}
	}
}
</style>
